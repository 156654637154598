import React from "react";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { ToolButton } from "../../../../components";
import { IToolButtonProps } from "../../../../interfaces";
import { useNavigate } from "react-router-dom";
import { hideAlert, showAlert } from "../../../../components/alert/Alert";
import { AlertTypeEnum } from "../../../../enums";

export default function CancelButton({ config, item }: IToolButtonProps) {
  const navigate = useNavigate();
  const { id: moduleId, services } = config;
  const { itemId } = item;

  const handleClick = () => {
    const handleYes = async () => {
      if (services.delete != null) await services.delete({ key: itemId });

      hideAlert();
      navigate(`/${moduleId}`, { replace: true });
    };

    showAlert({
      content: "Are you sure you want to delete this notification?",
      options: {
        actions: [
          { text: "Yes", onClick: handleYes, primary: true },
          { text: "No", onClick: hideAlert },
        ],
        type: AlertTypeEnum.Question,
      },
    });
  };

  return (
    <ToolButton
      text="Cancel"
      icon={faCancel}
      path={`/${moduleId}`}
      onClick={handleClick}
    />
  );
}
