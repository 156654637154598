import React from "react";
import { faLock, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import Helmet from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { AppLogo, Button } from "../../components";
import { TextField } from "../../components/fields";
import { useAuthentication, useStateReducer } from "../../hooks";
import { classNameBuilder } from "../../utilities";
import { global } from "../../_config";
import "./_styles.scss";

export default function LoginPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState: any = location.state;
  const [state, setState] = useStateReducer({
    username: "",
    password: "",
    submitting: false,
    error: false,
    loading: true,
  });
  const auth = useAuthentication();
  const from = locationState?.from?.pathname ?? "/";

  const handleChange = (prop: string, value: any) =>
    setState({ [prop]: value, errorHightlight: false });

  const handleSignIn = async () => {
    const res = await auth?.signIn(state.username, state.password);
    setState({ submitting: false });
    if (res) navigate(from, { replace: true });
    else {
      setState({ error: true, errorHightlight: true, password: "" });
      setTimeout(() => setState({ error: false }), 1000);
    }
  };

  const checkForSubmit = (e: any) => {
    if (e.key === "Enter") {
      handleSignIn();
      setState({ submitting: true });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setState({ loading: false });
    }, 1100);
  }, [setState]);

  return (
    <>
      <Helmet>
        <title>Sign In - {global.applicationName}</title>
      </Helmet>
      <div
        className={classNameBuilder(
          "h-app-login",
          state.loading ? "in" : "",
          state.error ? "error" : "",
          state.errorHightlight ? "error-highlight" : ""
        )}
      >
        <AppLogo />
        <div className="credentials">
          <TextField
            focus={!state.username}
            startAdornment={<FontAwesomeIcon icon={faUser} />}
            placeholder="Username or email address"
            value={state.username}
            onChange={(e: any) => handleChange("username", e.target.value)}
            onKeyDown={checkForSubmit}
            className="inputFields"
          />
          <TextField
            focus={state.error}
            type="password"
            placeholder="Password"
            startAdornment={<FontAwesomeIcon icon={faLock} />}
            value={state.password}
            onChange={(e: any) => handleChange("password", e.target.value)}
            onKeyDown={checkForSubmit}
            className="inputFields"
          />
        </div>
        <Button
          text="Sign In"
          primary
          raised
          onClick={handleSignIn}
          working={state.submitting}
        />
      </div>
    </>
  );
}
