import {
  IDeleteSingleArgs,
  IGetSingleArgs,
  IPageAndSearchArgs,
  ISearchArgs,
  IUpdateSingleArgs,
} from "../../../interfaces";
import { asyncify } from "../../../utilities";
import { goFetch } from "../../../utilities/goFetch";
import dummyLibraryData from "./_dummyData";

class Service {
  async getByPage({ search, pageNo }: IPageAndSearchArgs) {
    const res: any = await goFetch(
      `${process.env.REACT_APP_URL}notification/page?search=${search}&pageNo=${pageNo}`
    )
      .withHeaders({ "Content-Type": "application/json" })
      .get();
    const jsonResponse = await res.json();
    return jsonResponse;
  }

  async getSingle({ key }: IGetSingleArgs) {
    const res: any = await goFetch(
      `${process.env.REACT_APP_URL}notification/${key}`
    )
      .withHeaders({ "Content-Type": "application/json" })
      .get();
    const jsonResponse = await res.json();
    return jsonResponse;
  }

  async search({ search }: ISearchArgs) {
    return await asyncify(
      () => dummyLibraryData.filter((d) => new RegExp(search).test(d.name)),
      200
    );
  }

  async searchMembers({ search, pageNo }: IPageAndSearchArgs) {
    const res: any = await goFetch(
      `${
        process.env.REACT_APP_URL
      }users/members?pageNo=${pageNo}&pageSize=100&search=${encodeURI(
        search ?? ""
      )}`
    )
      .withHeaders({ "Content-Type": "application/json" })

      .get();
    const jsonResponse = await res.json();
    if (!jsonResponse?.data) return null;
    else return jsonResponse;
  }

  async getMemberships() {
    const res: any = await goFetch(
      `${process.env.REACT_APP_URL}users/memberships`
    )
      .withHeaders({ "Content-Type": "application/json" })
      .get();
    const jsonResponse = await res.json();
    if (!jsonResponse?.data) return null;
    else return jsonResponse.data;
  }

  async create() {}

  async createEntry(data: any) {
    const res: any = await goFetch(`${process.env.REACT_APP_URL}notification`)
      .withHeaders({ "Content-Type": "application/json" })
      .withBody({
        title: data.title,
        message: data.message,
        members: data.selectedMembers,
        memberships: data.selectedMemberships,
      })
      .post();
    const jsonResponse = await res.json();
    return jsonResponse;
  }

  async update({ key, ...rest }: IUpdateSingleArgs) {
    return await asyncify(() => {
      let toUpdate: any = dummyLibraryData.filter((d) => d.id === key)[0];

      Object.keys(rest).map((k: string) => {
        toUpdate[k] = rest[k];
        return k;
      });

      return toUpdate;
    }, 200);
  }

  async delete({ key }: IDeleteSingleArgs) {
    return await asyncify(async () => {
      const toDelete = await this.getSingle({ key });
      const index = dummyLibraryData.indexOf(toDelete);
      dummyLibraryData.splice(index, 1);
    }, 200);
  }
}

export default new Service();
