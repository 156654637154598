import { dataService } from "./services";
import { FancyCard, ViewItem } from "./components";
import { faBell, faMessage } from "@fortawesome/free-solid-svg-icons";
import { ModuleTypeEnum } from "../../enums";
import { IModuleConfig } from "../../interfaces";
import AddButton from "../notification-module/components/toolbar-items/AddButton";
import {
  BackButton,
  CancelButton,
  SaveButton,
} from "../notification-module/components/toolbar-items";
import { ItemPage } from "../notification-module/components";

const config: IModuleConfig = {
  id: "notification",
  name: "Notifications",
  icon: faBell,
  moduleType: ModuleTypeEnum.DualPaneLibrary,
  allowSearch: true,
  components: {
    libraryCard: FancyCard,
    itemPage: ItemPage,
  },
  services: {
    getByPage: dataService.getByPage,
    getSingle: dataService.getSingle,
    search: dataService.search,
    create: dataService.create,
    update: dataService.update,
    delete: dataService.delete,
  },
  utilities: {
    extractKey: (d: any) => d._id,
    extractItemPath: (d: any) => d._id,
    extractItemBreadcrumb: (d: any) => `${d.data.notificationTitle}`,
  },
  toolbarItems: {
    library: [AddButton],
    create: [],
    edit: [SaveButton, CancelButton],
    view: [BackButton, "|", AddButton],
  },
};

export default config;
