import "./_styles.scss";
import React from "react";
import { FormControl, InputAdornment, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

interface AdvertisementProps {
  title: string;
  link: string;
  url: string;
  dateStart: string;
  dateEnd: string;
  displayFrequency: string;
  onUpdateField: Function;
}

export default function EditItem(props: AdvertisementProps) {
  const title = props.title || "";
  const url = props.url || "";
  const link = props.link || "";
  const dateStart = props.dateStart || "";
  const dateEnd = props.dateEnd || "";
  const displayFrequency = props.displayFrequency || "";

  return (
    <div className="order-item">
      <div className="category-row">
        <FormControl fullWidth className="advertisements-field">
          <TextField
            label="Title"
            value={title || ""}
            onChange={(e: any) => props.onUpdateField("title", e.target.value)}
            error={!title}
          />
        </FormControl>
      </div>

      <FormControl fullWidth className="advertisements-field">
        <TextField
          label="Url"
          value={url || ""}
          onChange={(e: any) => props.onUpdateField("url", e.target.value)}
          helperText="The url from which the image needs to be retrieved"
          error={!url}
        />
      </FormControl>

      <FormControl fullWidth className="advertisements-field">
        <TextField
          value={link}
          onChange={(e: any) => props.onUpdateField("link", e.target.value)}
          label="Link"
          helperText="The link to which the user goes when clicking on the ad"
          error={!link}
        ></TextField>
      </FormControl>

      <div className="field-row">
        <FormControl fullWidth>
          <DesktopDatePicker
            label="Start Date"
            inputFormat="DD MMM YYYY"
            value={dateStart}
            onChange={(e: any) => {
              props.onUpdateField("dateStart", e._d);
            }}
            renderInput={(params) => <TextField {...params} />}
          ></DesktopDatePicker>
        </FormControl>
      </div>

      <div className="field-row">
        <FormControl fullWidth>
          <DesktopDatePicker
            label="End Date"
            inputFormat="DD MMM YYYY"
            value={dateEnd}
            onChange={(e: any) => props.onUpdateField("dateEnd", e._d)}
            renderInput={(params) => <TextField {...params} />}
          ></DesktopDatePicker>
        </FormControl>
      </div>

      <div className="field-row">
        <FormControl fullWidth>
          <TextField
            id="display-frequency"
            value={displayFrequency}
            label="Display Frequency"
            onChange={(e: any) =>
              props.onUpdateField("displayFrequency", e.target.value)
            }
            error={!displayFrequency}
            type="number"
            helperText="Measured in seconds"
          ></TextField>
        </FormControl>
      </div>
    </div>
  );
}
