import { dataService } from "./services";
import { MemberCard } from "./components";
import { faCalendarXmark } from "@fortawesome/free-solid-svg-icons";
import { ModuleTypeEnum } from "../../enums";
import { IModuleConfig } from "../../interfaces";
import { BackButton } from "../cancellations-module/components/toolbar-items";
import { ItemPage } from "../cancellations-module/components";

const config: IModuleConfig = {
  id: "cancellations",
  name: "Cancellations",
  icon: faCalendarXmark,
  moduleType: ModuleTypeEnum.DualPaneLibrary,
  allowSearch: true,
  components: {
    libraryCard: MemberCard,
    itemPage: ItemPage,
  },
  services: {
    getByPage: dataService.getMembersWithCancellations,
    getSingle: dataService.getSingle,
    // search: dataService.searchMembers,
  },
  utilities: {
    extractKey: (d: any) => d._id,
    extractItemPath: (d: any) => d._id,
    extractItemBreadcrumb: (d: any) => `${d.title}`,
  },
  toolbarItems: {
    library: [],
    create: [],
    edit: [],
    view: [BackButton],
  },
};

export default config;
