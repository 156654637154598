import React, { useState } from "react";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { ToolButton } from "../../../../components";
import { IToolButtonProps } from "../../../../interfaces";
import { hideAlert, showAlert } from "../../../../components/alert/Alert";
import { AlertTypeEnum } from "../../../../enums";
import { useStateReducer, useSubscription } from "../../../../hooks";
import { useNavigate } from "react-router-dom";
import Service from "../../services/dataService";

export default function SaveButton({ config, item }: IToolButtonProps) {
  const navigate = useNavigate();

  const [state, SetState] = useStateReducer({
    searchMember: "",
    selectedMembers: [],
    selectedMemberships: [],
    nameTitle: "",
    message: "",
  });
  const { id: moduleId, services } = config;
  useSubscription("notification", (notification: any) => {
    SetState(notification);
  });
  const sendNotification = async () => {
    await Service.createEntry({
      title: state.nameTitle,
      message: state.message,
      selectedMembers: state.selectedMembers,
      selectedMemberships: state.selectedMemberships,
    });
    hideAlert();
    navigate(`/${moduleId}`, { replace: false });
  };

  const handleClick = async () => {
    if (state.nameTitle === "" || state.message === "")
      showAlert({
        content: "Please complete the title and message body.",
        options: {
          actions: [{ text: "OK", onClick: hideAlert }],
          type: AlertTypeEnum.Warning,
        },
      });
    else {
      showAlert({
        content: "Are you sure you want to send this notification ?",
        options: {
          actions: [
            { text: "Yes", onClick: sendNotification, primary: true },
            { text: "No", onClick: hideAlert },
          ],
          type: AlertTypeEnum.Warning,
        },
      });
    }
    return state.nameTitle === "" || state.message === "" ? false : true;
  };

  return (
    <ToolButton
      text="Queue Notification"
      icon={faSave}
      path={`/`}
      primary
      raised
      onClick={handleClick}
    />
  );
}
