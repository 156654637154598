import React from "react";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { ToolButton } from "../../../../components";
import { IToolButtonProps } from "../../../../interfaces";
import { hideAlert, showAlert } from "../../../../components/alert/Alert";
import { AlertTypeEnum, StateTopicEnum } from "../../../../enums";
import { publish, useStateReducer, useSubscription } from "../../../../hooks";
import { useLocation, useNavigate } from "react-router-dom";
import Service from "../../services/dataService";
import { getQueryParameter } from "../../../../utilities";

export default function SaveButton({ config, item }: IToolButtonProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const _id = location.pathname.substring(14);
  const mode = getQueryParameter(location.search, "mode");

  const [state, setState] = useStateReducer({
    type: null,
    category: null,
    description: null,
    documentLink: null,
  });
  const { id: moduleId } = config;

  useSubscription("_publication", (publication: any) => {
    setState({ ...publication });
  });

  const savePublication = async () => {
    if (mode == "edit")
      await Service.updatePublication(_id, {
        ...state,
      });

    if (mode == "create")
      await Service.createPublication({
        ...state,
      });
    publish(StateTopicEnum.RefreshLibrary, null);
    hideAlert();
    navigate(`/${moduleId}`, { replace: false });
  };

  const handleClick = async () => {
    if (!state.type || !state.description || !state.documentLink) {
      showAlert({
        content:
          "Please complete the type, description and document link fields in order to save.",
        options: {
          actions: [{ text: "OK", onClick: hideAlert }],
          type: AlertTypeEnum.Warning,
        },
      });
      return;
    } else {
      showAlert({
        content: "Are you sure you want to save this publication?",
        options: {
          actions: [
            { text: "Yes", onClick: savePublication, primary: true },
            { text: "No", onClick: hideAlert },
          ],
          type: AlertTypeEnum.Warning,
        },
      });
    }
  };

  return (
    <ToolButton
      text="Save"
      icon={faSave}
      path={`/`}
      primary
      raised
      onClick={handleClick}
    />
  );
}
