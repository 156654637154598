import React from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ToolButton } from "../../../../components";
import { IToolButtonProps } from "../../../../interfaces";
import Service from "../../services/dataService";
import { hideAlert, showAlert } from "../../../../components/alert/Alert";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertTypeEnum } from "../../../../enums";

export default function DeleteButton({ config }: IToolButtonProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { id: moduleId } = config;
  const _id = location.pathname.substring(14);

  const deletePublication = async () => {
    await Service.deletePublication(_id);

    hideAlert();
    navigate(`/${moduleId}`, { replace: false });
  };

  const handleClick = async () => {
    showAlert({
      content: "Are you sure you want to delete this publication?",
      options: {
        actions: [
          { text: "Yes", onClick: deletePublication, primary: true },
          { text: "No", onClick: hideAlert },
        ],
        type: AlertTypeEnum.Warning,
      },
    });
  };

  return (
    <ToolButton
      text="Delete"
      icon={faTrash}
      path={`/${moduleId}`}
      onClick={handleClick}
    />
  );
}
