import React from "react";
import { generateColorFromString } from "../../utilities";
import { Clickable } from "../";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import "./_styles.scss";
import { hideAlert, showAlert } from "../alert/Alert";
import { useAuthentication } from "../../hooks";
import { global } from "../../_config";
import { AlertTypeEnum } from "../../enums";

interface UserAccountProps {
  allowSettings?: boolean;
}

export default function UserAccount({ allowSettings }: UserAccountProps) {
  const auth: any = useAuthentication();
  const { extractAccountNameFromUser, extractDisplayNameFromUser } =
    global.utilities;
  const displayName = extractDisplayNameFromUser(auth?.user);
  const accountName = extractAccountNameFromUser(auth?.user);
  const backgroundColor = accountName
    ? generateColorFromString(accountName, 30, 80)
    : "#ccc";
  let initials = "";

  const usernameParts = displayName?.split(" ") ?? "";

  if (usernameParts.length > 1)
    initials = usernameParts[0][0] + usernameParts[1][0];
  else initials = displayName?.substring(0, 2) ?? "!!";

  const handleYesClick = () => {
    hideAlert();
    auth?.signOut();
  };

  const handleSignOut = () => {
    showAlert({
      content: "Are you sure you want to sign out of your account?",
      options: {
        actions: [
          { text: "Yes", onClick: handleYesClick, primary: true },
          { text: "No", onClick: hideAlert },
        ],
        type: AlertTypeEnum.Question,
      },
    });
    return false;
  };

  let capitalizedName =
    auth?.user?.userType?.charAt(0).toUpperCase() +
    auth?.user?.userType?.slice(1);

  return (
    <div className="h-app-user-account">
      <Clickable className="user" isNavLink>
        <span className="avatar" style={{ background: backgroundColor }}>
          {auth?.user?.userType?.substring(0, 1) || "A"}
        </span>
        <span className="name hide-text-overflow">
          {capitalizedName || "Admin"}
        </span>
      </Clickable>

      <Clickable className="sign-out" onClick={handleSignOut}>
        <FontAwesomeIcon icon={faPowerOff} />
      </Clickable>
    </div>
  );
}
