import React from "react";
import { Route, Routes } from "react-router-dom";
import { INavigableComponent } from "./interfaces";
import {
  Error404,
  HomePage,
  ItemPage,
  LoginPage,
  ModulePage,
  ProfilePage,
  SettingsPage,
} from "./pages";

export default function AppRoutes({
  setBreadcrumbs,
  setToolbarConfig,
}: INavigableComponent) {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <HomePage
            setBreadcrumbs={setBreadcrumbs}
            setToolbarConfig={setToolbarConfig}
          />
        }
      />
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/profile"
        element={
          <ProfilePage
            setBreadcrumbs={setBreadcrumbs}
            setToolbarConfig={setToolbarConfig}
          />
        }
      />
      <Route
        path="/settings"
        element={
          <SettingsPage
            setBreadcrumbs={setBreadcrumbs}
            setToolbarConfig={setToolbarConfig}
          />
        }
      />
      <Route
        path="/:moduleId"
        element={
          <ModulePage
            setBreadcrumbs={setBreadcrumbs}
            setToolbarConfig={setToolbarConfig}
          />
        }
      >
        <Route
          index
          element={
            <ItemPage
              index
              setBreadcrumbs={setBreadcrumbs}
              setToolbarConfig={setToolbarConfig}
            />
          }
        />
        <Route
          path="/:moduleId/:itemId"
          element={
            <ItemPage
              setBreadcrumbs={setBreadcrumbs}
              setToolbarConfig={setToolbarConfig}
            />
          }
        />
      </Route>
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
}
