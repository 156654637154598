import React from "react";
import "./_styles.scss";

interface FancyCardProps {
  data?: any;
}

export default function FancyCard({ data }: FancyCardProps) {
  const { description } = data;

  return <p className="fancy-card">{description}</p>;
}
