import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { Scrollable } from "../../../../components";
import LibraryCard from "../../../../components/library/LibraryCard";
import { useStateReducer } from "../../../../hooks";
import { dataService } from "../../services";
import "./_styles.scss";

interface Publication {
  type: string;
  category: string;
  description: string;
  documentLink: string;
}

export default function ViewItem(props: Publication) {
  const [state, setState] = useStateReducer({
    types: null,
    categories: null,
    type: "",
    category: "",
  });

  const getTypes = async () => {
    const typeResponse = await dataService.getAllTypes();

    const type = typeResponse?.filter((typ: any) => typ.slug == props.type);
    setState({ types: typeResponse, type });
  };
  const getCategories = async () => {
    const catResponse = await dataService.getAllCategories();
    const category = catResponse?.filter(
      (cat: any) => cat.slug == props.category
    );

    setState({
      categories: catResponse,
      category,
    });
  };

  useEffect(() => {
    getCategories();
    getTypes();
  }, []);

  return (
    <div className="order-item">
      <div className="category-row">
        <FormControl fullWidth className="publications-field">
          <TextField
            label="Type"
            inputProps={{ readOnly: true }}
            value={state.type[0]?.name || ""}
          />
        </FormControl>
      </div>

      <div className="category-row">
        <FormControl fullWidth className="publications-field">
          <TextField
            label="Category"
            inputProps={{ readOnly: true }}
            value={state.category[0]?.name || ""}
          />
        </FormControl>
      </div>

      <FormControl fullWidth className="publications-field">
        <TextField
          id="description"
          value={props.description}
          label="Description"
          placeholder="Please give this document a name"
          inputProps={{ readOnly: true }}
        ></TextField>
      </FormControl>

      <FormControl fullWidth>
        <TextField
          id="document-link"
          value={props.documentLink}
          label="Document Link"
          placeholder="https://storage.com/image/1.jpg"
          inputProps={{ readOnly: true }}
        ></TextField>
      </FormControl>
    </div>
  );
}
