import React from "react";
import { FormControl, TextField } from "@mui/material";
import { useStateReducer } from "../../../../hooks";

interface editModal {
  itemType: string;
  name?: string;
  slug?: string;
  onUpdateValue: Function;
}

export const EditModal = (props: editModal) => {
  const [state, setState] = useStateReducer({
    name: props.name || "",
    slug: props.slug || "",
  });

  return (
    <div className="edit-modal">
      <FormControl fullWidth className="item-form-field">
        <TextField
          id={`${props.itemType}-name`}
          value={state.name}
          label={`${props.itemType} Name`}
          onChange={(e) => setState({ name: e.target.value })}
          onBlur={() =>
            props.onUpdateValue(
              `${props.itemType.toLowerCase()}Name`,
              state.name
            )
          }
          required
        ></TextField>
      </FormControl>
      <FormControl fullWidth className="item-form-field">
        <TextField
          id={`${props.itemType}-slug`}
          value={state.slug}
          label={`${props.itemType} Slug`}
          placeholder="eg.: sector_specific_guidance"
          onChange={(e) => setState({ slug: e.target.value })}
          onBlur={() =>
            props.onUpdateValue(
              `${props.itemType.toLowerCase()}Slug`,
              state.slug
            )
          }
          required
        ></TextField>
        <div className="slug-hint">
          Please use all lowercase and underscores ( _ ) to separate words
        </div>
      </FormControl>
    </div>
  );
};
