import { Nullable } from "../types";

interface FetchWrapperDefaults {
  appRoot: string;
  queryParameters: Object;
  headers: Object;
  defaultErrorHandler: Function;
  defaultResponseParser: Function;
}

type Method = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

let defaultQueryParameters: Object = {};
let defaultHeaders: Object = {};
let defaultAppRoot: string = "";
let defaultErrorHandler: Nullable<Function> = null;
let defaultResponseParser: Nullable<Function> = null;

const setHeaders = (headers: Headers, toSet?: any): void => {
  Object.keys(toSet ?? {}).forEach((k) => {
    const h: any = toSet[k];

    headers.set(k, h instanceof Function ? h() : h);
  });
};

const addQueryParameters = (url: string, parameters?: any): string => {
  if (!url || !parameters) return url;

  Object.keys(parameters).forEach((k: string) => {
    let p = parameters[k];
    let v = p instanceof Function ? p() : p;
    v = typeof v === "object" ? JSON.stringify(v) : v;
    let c = url.indexOf("?") >= 0 ? "&" : "?";
    url += `${c}${encodeURIComponent(k)}=${encodeURIComponent(v)}`;
  });

  return url;
};

const prepareBodyWithFile = (body: any): any => {
  // if with file is passed, withBody should be sent through without stringifying
  // TODO: Determine whether a body has to be sent or if it can be omitted
  return body;
};

const buildFetch = (fw: FetchWrapper): Promise<any> => {
  return new Promise((resolve: Function, reject: Function) =>
    fetch(
      `${defaultAppRoot}${addQueryParameters(fw.url, defaultQueryParameters)}`,
      {
        method: fw.method,
        headers: (() => {
          const headers = new Headers();

          setHeaders(headers, defaultHeaders);
          setHeaders(headers, fw.headers);

          return headers;
        })(),
        credentials: "include",
        body: fw.body
          ? JSON.stringify(fw.body)
          : fw.data
          ? prepareBodyWithFile(fw.data)
          : null,
      }
    )
      .then((r: Object) => {
        return defaultResponseParser
          ? defaultResponseParser(r)
          : new Promise((_resolve: Function) => _resolve(r));
      })
      .then((r: Object) => resolve(r))
      .catch((err: Error) =>
        defaultErrorHandler
          ? defaultErrorHandler(err, resolve, reject)
          : reject(err)
      )
  );
};

/**
 * A wrapper for fetch calls providing a composable API.
 */
export class FetchWrapper {
  url: string;
  headers: Object;
  body: any;
  data: Nullable<Object>;
  method: Method;

  constructor(url: string) {
    this.url = url;
    this.headers = {};
    this.data = null;
    this.method = "GET";
  }

  cleanUrl = (values?: any) => {
    Object.keys(values ?? {}).forEach((k) => {
      let v = values[k];
      this.url = this.url.replace(`{${k}}`, v);
    });

    return this;
  };

  withHeaders = (headers: Object) => {
    this.headers = headers;

    return this;
  };

  withBody = (body: any) => {
    this.body = body;

    return this;
  };

  withFormData = (data: Object) => {
    this.data = data;

    return this;
  };

  withQueryParameters = (parameters: Object) => {
    this.url = addQueryParameters(this.url, parameters);

    return this;
  };

  get = (): Promise<FetchWrapper> => {
    this.method = "GET";

    return buildFetch(this);
  };

  post = (): Promise<FetchWrapper> => {
    this.method = "POST";

    return buildFetch(this);
  };

  put = (): Promise<FetchWrapper> => {
    this.method = "PUT";

    return buildFetch(this);
  };

  delete = (): Promise<FetchWrapper> => {
    this.method = "DELETE";

    return buildFetch(this);
  };

  patch = (): Promise<FetchWrapper> => {
    this.method = "PATCH";

    return buildFetch(this);
  };
}

/**
 * Goes to the specified url and performs a fetch.
 * @param {string} url The url to go fetch from
 * @returns {FetchWrapper} A FetchWrapper object to compose the call with
 */
const goFetch = (url: string): FetchWrapper => new FetchWrapper(url);

/**
 * Configures default settings for the GoFetch library.
 * @param {Object} defaults The defaults to configure the library with
 * @param {string} defaults.appRoot The default root for the fetch calls
 * @param {Object} defaults.queryParameters The default query parameters to be applied to all fetch calls
 * @param {Object} defaults.headers The default headers to be applied to all fetch calls
 */
const configureDefaults = (defaults: FetchWrapperDefaults): void => {
  defaultAppRoot = defaults.appRoot;
  defaultQueryParameters = defaults.queryParameters;
  defaultHeaders = defaults.headers;
  defaultErrorHandler = defaults.defaultErrorHandler;
  defaultResponseParser = defaults.defaultResponseParser;
};

const contentTypes = {
  json: "application/json",
  html: "text/html",
  text: "text/plain",
  xml: "text/xml",
  encodedForm: "application/x-www-form-urlencoded",
  multipartForm: "multipart/form-data",
  pdf: "application/pdf",
};

export { goFetch, configureDefaults, contentTypes };
