import React from "react";
import "./_styles.scss";
import { global } from "../../_config";
import { NavLink } from "react-router-dom";

export default function AppLogo() {
  const Component = global.components.appLogo;

  return (
    <NavLink className="h-app-logo" to="/">
      <Component />
    </NavLink>
  );
}
