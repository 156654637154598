import React from "react";
import { useEffect } from "react";
import { PageWrapper } from "../../components";
import { INavigableComponent } from "../../interfaces";
import { global } from "../../_config";
import "./_styles.scss";

export default function ProfilePage({
  setBreadcrumbs,
  setToolbarConfig,
}: INavigableComponent) {
  useEffect(() => {
    setBreadcrumbs?.([{ text: "My Profile" }]);
    setToolbarConfig?.([]);
  }, []);

  const Component = global.components.userProfile;
  const meta = { title: "My Profile" };

  return (
    <PageWrapper meta={meta} secure>
      <div className="h-app-profile-page-content">
        <Component />
      </div>
    </PageWrapper>
  );
}
