enum StateTopicEnum {
  LibrarySearch = "_librarySearch",
  LibraryPage = "_libraryPage",
  LibrarySearchAndPage = "_librarySearchAndPage",
  Modal = "_modal",
  Alert = "_alert",
  User = "_user",
  Publication = "_publication",
  Members = "_members",
  SelectedMember = "_selectedMember",
  Advertisement = "_advertisement",
  RefreshLibrary = "_refreshLibrary",
}

export default StateTopicEnum;
