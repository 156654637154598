const primary = {
  light: "rgba(100, 38, 103, 0.85)",
  main: "rgba(100, 38, 103, 0.85)",
  dark: "#006185",
};

const secondary = {
  light: "#91e1ff",
  main: "rgba(100, 38, 103, 0.85)",
  dark: "#006185",
};

const error = {
  light: "#e57373",
  main: "#f44336",
  dark: "#d32f2f",
};

const warning = {
  light: "#ffb74d",
  main: "#ffa726",
  dark: "#f57c00",
};

const info = {
  light: "#4fc3f7",
  main: "rgba(100, 38, 103, 0.85)",
  dark: "#0288d1",
};

const success = {
  light: "#81c784",
  main: "#66bb6a",
  dark: "#388e3c",
};

export { primary, secondary, error, warning, info, success };
