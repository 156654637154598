import React from "react";
import "./_styles.scss";

interface AdCardProps {
  data?: any;
}

export default function AdCard({ data }: AdCardProps) {
  const { title } = data;

  return <p className="fancy-card">{title}</p>;
}
