import "./_styles.scss";
import React, { useEffect } from "react";
import { Scrollable } from "../../../../components";
import { Checkbox } from "../../../../components/fields";
import { Button, ButtonBase, InputBase, Pagination } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faSearch } from "@fortawesome/free-solid-svg-icons";
import TextField from "@mui/material/TextField";
import { IModuleConfig } from "../../../../interfaces";
import { dataService } from "../../services";
import { publish, useStateReducer } from "../../../../hooks";

interface EditItemProps {
  data?: any;
  config: IModuleConfig;
}

export default function EditItem({ data, config }: EditItemProps) {
  const { status, name } = data;
  const { getMemberships, searchMembers } = dataService;
  const [membershipState, setMembershipState] = useStateReducer({
    members: [],
    memberships: [],
  });
  const [state, setState] = useStateReducer({
    searchMember: "",
    selectedMembers: [],
    selectedMemberships: [],
    pageNumber: 1,
    pageCount: 100,
    nameTitle: "",
    message: "",
  });
  const { searchMember, pageNumber } = state;

  const handleChange = (item: string, selected: boolean) => {
    let items: string[] = Array.from(state.selectedMembers);

    if (selected && !items.includes(item)) items.push(item);

    if (!selected && items.includes(item))
      items.splice(state.selectedMembers.indexOf(item), 1);
    setState({ selectedMembers: items });
    publish("notification", { ...state, selectedMembers: items });
  };

  const handleMembershipChange = (item: string, selected: boolean) => {
    let items: string[] = Array.from(state.selectedMemberships);

    if (selected && !items.includes(item)) items.push(item);

    if (!selected && items.includes(item))
      items.splice(state.selectedMemberships.indexOf(item), 1);
    setState({ selectedMemberships: items });
    publish("notification", { ...state, selectedMemberships: items });
  };

  const changeTitle = (event: any) => {
    setState({ nameTitle: event.target.value });
    publish("notification", { ...state, nameTitle: event.target.value });
  };

  const changeMessage = (event: any) => {
    setState({ message: event.target.value });
    publish("notification", { ...state, message: event.target.value });
  };

  const handleSearch = (search: string) => {
    setState({ searchMember: search });
  };

  const handleClearSearch = () => {
    setState({ searchMember: "" });
  };

  const setPage = (_: any, p: number) => {
    setState({ pageNumber: p });
  };

  const setMemberName = () => {
    setState({ message: state.message + " {member}" });
    publish("notification", { ...state, message: state.message + " {member}" });
  };

  const setAmountDue = () => {
    setState({ message: state.message + " {amount}" });
    publish("notification", { ...state, message: state.message + " {amount}" });
  };

  useEffect(() => {
    var membershipEntries: any[] = [];
    const loadData = async () => {
      membershipEntries = await dataService.getMemberships();
      setState({
        name: name,
        selectedMembers: data.members ?? [],
        selectedMemberships: data.memberships ?? [],
      });
      setMembershipState({ memberships: membershipEntries });
    };
    loadData();
  }, [getMemberships]);

  useEffect(() => {
    const loadData = async () => {
      const res = await dataService.searchMembers({
        search: searchMember,
        pageNo: pageNumber,
      });

      setState({
        pageCount: res.pageCount,
      });
      setMembershipState({ members: res.data });
    };

    loadData();
  }, [pageNumber, searchMember, searchMembers]);

  return (
    <div className="order-item">
      <div className="orderinner">
        <TextField
          id="standard-basic"
          key="titleEntry"
          label="Title"
          variant="outlined"
          value={state.nameTitle}
          onChange={changeTitle}
          error={state.nameTitle === ""}
          style={{ paddingBottom: "10px", width: "50%" }}
        />
        <div
          style={{
            display: "flex",
          }}
        >
          <h1
            style={{
              paddingRight: "4px",
            }}
          >
            Status:
          </h1>
          <h1 className="heading">{status ? status : "Unknown"}</h1>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <div
          className="filterContainer"
          style={{
            marginRight: "10px",
          }}
        >
          <h1
            className="heading content"
            style={{ marginBottom: "4px", marginLeft: "4px", marginTop: "4px" }}
          >
            Member Filters
          </h1>
          <InputBase
            key="searchEditEntry"
            className="h-app-search-box-members"
            placeholder="Search"
            value={searchMember}
            onChange={(e: any) => {
              handleSearch(e.target.value);
            }}
            startAdornment={<FontAwesomeIcon icon={faSearch} />}
            endAdornment={
              searchMember ? (
                <ButtonBase
                  key="searchClose"
                  className="close-search"
                  onClick={handleClearSearch}
                >
                  <FontAwesomeIcon icon={faClose} />
                </ButtonBase>
              ) : null
            }
          />
          <div className="liststyle" style={{ height: "22vh" }}>
            <Scrollable key="memberScroll">
              <div className="h-app-library-items">
                {!membershipState.members ||
                membershipState.members.length < 1 ? (
                  <span className="loading content">No Members.</span>
                ) : (
                  membershipState.members.map((d: any, key: number) => {
                    return (
                      <div
                        key={`member-${key}`}
                        style={{
                          display: "block",
                          paddingLeft: "10px",
                          height: "2px !important",
                        }}
                      >
                        <Checkbox
                          key={"member-inner-" + d}
                          className="h-app-input-field-members"
                          label={d}
                          onChange={(e: any) =>
                            handleChange(d, e.target.checked)
                          }
                          labelPlacement="start"
                          value={state.selectedMembers.includes(d)}
                        />
                      </div>
                    );
                  })
                )}
              </div>
            </Scrollable>
            <Pagination
              key={"memberPage"}
              style={{ width: "100%" }}
              className="pager"
              shape="rounded"
              size="small"
              page={state.pageNumber}
              count={state.pageCount}
              color="primary"
              siblingCount={0}
              boundaryCount={1}
              showFirstButton
              showLastButton
              onChange={setPage}
            />
          </div>
        </div>

        <div className="filterContainer">
          <h1 className="heading content">Membership Filters</h1>
          <div
            style={{
              display: "inline",
            }}
          ></div>
          <div className="liststyle">
            <Scrollable className="items" key="membershipScroll">
              <div
                key="membership"
                className="h-app-library-items-filter"
                style={{ height: "90% !important" }}
              >
                {!membershipState.memberships ||
                membershipState.memberships.length < 1 ? (
                  <span className="loading content">No Memberships Found.</span>
                ) : (
                  membershipState.memberships.map((d: any) => {
                    return (
                      <div
                        style={{
                          display: "block",
                          paddingLeft: "10px",
                          height: "2px !important",
                        }}
                      >
                        <Checkbox
                          key={"membership-inner-" + d}
                          className="h-app-input-field-members"
                          label={d}
                          onChange={(e: any) =>
                            handleMembershipChange(d, e.target.checked)
                          }
                          labelPlacement="start"
                          value={state.selectedMemberships.includes(d) ?? false}
                        />
                      </div>
                    );
                  })
                )}
              </div>
            </Scrollable>
          </div>
        </div>
        <h3> Message Detail</h3>
        <div style={{ display: "inline-block", width: "100%" }}>
          <TextField
            key="messageField"
            label="Message Field"
            multiline={true}
            rows={10}
            style={{ width: "73%" }}
            onChange={changeMessage}
            error={state.message === ""}
            value={state.message}
          />
          <div
            style={{ display: "inline-block", height: "240px", width: "25%" }}
          >
            <h3
              style={{
                marginTop: "0px",
                marginLeft: "4px",
                marginBottom: "2px",
              }}
            >
              Properties
            </h3>
            <div
              style={{
                backgroundColor: "#f5f5f5",
                height: "100%",
                width: "100%",
                marginLeft: "2%",
              }}
            >
              <Button
                key="memberNameProperty"
                variant="contained"
                className="propertyButton"
                onClick={setMemberName}
              >
                Member Name
              </Button>
              <Button
                key="memberDueProperty"
                className="propertyButton"
                variant="contained"
                onClick={setAmountDue}
              >
                Amount Due
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
