import { v4 as uuidv4 } from "uuid";

const dummyLibraryData = [...Array(2).keys()].map((v) => ({
  id: uuidv4(),
  name: `Notifcation test ${v + 1}`,
  description: `Summary of order ${
    v + 1
  } This is a test notification.This is a test notification.This is a test notification.This is a test notification.This is a test notification.This is a test notification.This is a test notification.This is a test notification`,
  status: "pending",
  members: [1, 2, 3, 4, 5, 6, 7, 8, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
  memberships: ["alumni", "alumni2"],
}));

export default dummyLibraryData;
