import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { useStateReducer } from "../../../../hooks";
import { dataService } from "../../services";
import "./_styles.scss";

interface Advertisement {
  title: string;
  link: string;
  url: string;
  dateStart: string;
  dateEnd: string;
  displayFrequency: string;
}

export default function ViewItem(props: Advertisement) {
  const { title, url, link, dateStart, dateEnd, displayFrequency } = props;

  console.log({ props });

  return (
    <div className="order-item">
      <div className="category-row">
        <FormControl fullWidth className="advertisements-field">
          <TextField
            label="Title"
            inputProps={{ readOnly: true, disabled: true }}
            value={title || ""}
          />
        </FormControl>
      </div>

      <FormControl fullWidth className="advertisements-field">
        <TextField
          label="Url"
          inputProps={{ readOnly: true, disabled: true }}
          value={url || ""}
          helperText="The url from which the image needs to be retrieved"
        />
      </FormControl>

      <FormControl fullWidth className="advertisements-field">
        <TextField
          id="link"
          value={link}
          label="Link"
          inputProps={{ readOnly: true, disabled: true }}
          helperText="The link to which the user goes when clicking on the ad"
        ></TextField>
      </FormControl>

      <div className="field-row">
        <FormControl fullWidth>
          <TextField
            id="date-start"
            value={moment(dateStart).format("DD MMMM YYYY")}
            label="Start Date"
            inputProps={{ readOnly: true, disabled: true }}
          ></TextField>
        </FormControl>
      </div>

      <div className="field-row">
        <FormControl fullWidth>
          <TextField
            id="date-end"
            value={moment(dateEnd).format("DD MMMM YYYY")}
            label="End Date"
            inputProps={{ readOnly: true, disabled: true }}
          ></TextField>
        </FormControl>
      </div>

      <div className="field-row">
        <FormControl fullWidth>
          <TextField
            id="display-frequency"
            value={displayFrequency}
            label="Display frequency"
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">seconds</InputAdornment>
              ),
            }}
          ></TextField>
        </FormControl>
      </div>
    </div>
  );
}
