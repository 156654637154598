import React from "react";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { ToolButton } from "../../../../components";
import { IToolButtonProps } from "../../../../interfaces";
import { hideAlert, showAlert } from "../../../../components/alert/Alert";
import { AlertTypeEnum, StateTopicEnum } from "../../../../enums";
import { publish, useStateReducer, useSubscription } from "../../../../hooks";
import { useLocation, useNavigate } from "react-router-dom";
import Service from "../../services/dataService";
import { getQueryParameter } from "../../../../utilities";

export default function SaveButton({ config, item }: IToolButtonProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const _id = location.pathname.substring(16);
  const mode = getQueryParameter(location.search, "mode");

  const [state, setState] = useStateReducer({
    title: null,
    url: null,
    link: null,
    dateStart: null,
    dateEnd: null,
    displayFrequency: null,
  });
  const { id: moduleId } = config;

  useSubscription(StateTopicEnum.Advertisement, (advertisement: any) => {
    setState({ ...advertisement });
  });

  const saveAdvertisement = async () => {
    try {
      if (mode == "edit")
        await Service.updateAdvertisement(_id, {
          ...state,
        });

      if (mode == "create") {
        await Service.createAdvertisement({
          ...state,
        });
      }
      publish(StateTopicEnum.RefreshLibrary, null);
      hideAlert();
      navigate(`/${moduleId}`, { replace: false });
    } catch (err: any) {
      hideAlert();
    }
  };

  const handleClick = async () => {
    if (
      !state.title ||
      !state.url ||
      !state.link ||
      !state.dateStart ||
      !state.dateEnd ||
      !state.displayFrequency
    ) {
      showAlert({
        content: "Please complete all fields in order to save.",
        options: {
          actions: [{ text: "OK", onClick: hideAlert }],
          type: AlertTypeEnum.Warning,
        },
      });
      return;
    } else {
      showAlert({
        content: "Are you sure you want to save this advertisement?",
        options: {
          actions: [
            { text: "Yes", onClick: saveAdvertisement, primary: true },
            { text: "No", onClick: hideAlert },
          ],
          type: AlertTypeEnum.Warning,
        },
      });
    }
  };

  return (
    <ToolButton
      text="Save"
      icon={faSave}
      path={`/`}
      primary
      raised
      onClick={handleClick}
    />
  );
}
