import { StateTopicEnum } from "../../../enums";
import { getCurrentData, publish, useSubscription } from "../../../hooks";
import {
  IGetSingleArgs,
  IPageAndSearchArgs,
  ISearchArgs,
} from "../../../interfaces";
import { asyncify } from "../../../utilities";
import { goFetch } from "../../../utilities/goFetch";

class Service {
  async finalizeCancellation(memberId: number, eventId: number) {
    const res: any = await goFetch(
      `${process.env.REACT_APP_URL}events/finalize-cancellation`
    )
      .withHeaders({ "Content-Type": "application/json" })
      .withBody({
        memberId,
        eventId,
      })
      .post();
    const jsonResponse = await res.json();
    return jsonResponse;
  }

  async revertCancellation(memberId: number, eventId: number) {
    const res: any = await goFetch(
      `${process.env.REACT_APP_URL}events/revert-cancellation`
    )
      .withHeaders({ "Content-Type": "application/json" })
      .withBody({
        memberId,
        eventId,
      })
      .post();
    const jsonResponse = await res.json();
    return jsonResponse;
  }

  async getEventDetails(id: any) {
    const response: any = await goFetch(
      `${process.env.REACT_APP_URL}events/${id}`
    )
      .withHeaders({ "Content-Type": "application/json" })
      .get();

    const jsonResponse = response.json();

    return jsonResponse;
  }

  async getSingle({ key }: IGetSingleArgs) {
    const members = getCurrentData(StateTopicEnum.Members) ?? "";
    const response = members.data?.filter((m: any) => m._id == key);

    if (response == null) return;
    publish(StateTopicEnum.SelectedMember, response[0]);

    return response[0];
  }

  async getMembersWithCancellations({ search, pageNo }: IPageAndSearchArgs) {
    const res: any = await goFetch(
      `${
        process.env.REACT_APP_URL
      }events/cancellations?pageNo=${pageNo}&pageSize=100&search=${encodeURI(
        search ?? ""
      )}`
    )
      .withHeaders({ "Content-Type": "application/json" })
      .get();

    const jsonResponse = await res.json();
    if (!jsonResponse?.data) return null;
    else {
      publish(StateTopicEnum.Members, jsonResponse);
      return jsonResponse;
    }
  }
}

export default new Service();
