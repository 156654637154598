import React from "react";
import { useLocation } from "react-router-dom";
import getQueryParameter from "../../../../utilities/getQueryParameter";
import { ViewItem } from "..";
import { useEffect } from "react";
import { INavigableComponent } from "../../../../interfaces";
import { StateTopicEnum, ToolbarContextEnum } from "../../../../enums";
import EditItem from "../edit-item/EditItem";
import { publish, useStateReducer } from "../../../../hooks";

interface ItemPageProps {
  config: any;
  data: any;
}

export default function ItemPage({
  config,
  data,
  setBreadcrumbs,
  setToolbarConfig,
}: ItemPageProps & INavigableComponent) {
  const location = useLocation();
  const mode = getQueryParameter(location.search, "mode");

  const [state, setState] = useStateReducer({
    title: data?.data?.title || "",
    url: data?.data?.url || "",
    link: data?.data?.link,
    dateStart: data?.data?.dateStart || new Date(),
    dateEnd: data?.data?.dateEnd || new Date(),
    displayFrequency: data?.data?.displayFrequency || 0,
  });

  useEffect(() => {
    let context;
    switch (mode) {
      case "edit":
        context = ToolbarContextEnum.EditItem;
        break;
      case "create":
        context = ToolbarContextEnum.EditItem;
        break;
      default:
        context = ToolbarContextEnum.ViewItem;
        break;
    }

    setToolbarConfig?.({ ...config, context, item: data });
  }, [mode]);

  const onUpdateField = (target: string, value: any) => {
    setState({ [target]: value });
    setTimeout(() => {
      publish(StateTopicEnum.Advertisement, { ...state, [target]: value });
    }, 300);
  };

  switch (mode) {
    case "edit":
      return (
        <EditItem {...state} onUpdateField={onUpdateField} config={config} />
      );
    case "create":
      return (
        <EditItem
          {...state}
          onUpdateField={onUpdateField}
          config={config}
          mode
        />
      );
    default:
      return <ViewItem {...data.data} />;
  }
}
