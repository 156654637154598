import { dataService } from "./services";
import { AdCard } from "./components";
import { faRectangleAd } from "@fortawesome/free-solid-svg-icons";
import { ModuleTypeEnum } from "../../enums";
import { IModuleConfig } from "../../interfaces";
import AddButton from "../advertisements-module/components/toolbar-items/AddButton";
import {
  BackButton,
  CancelButton,
  EditButton,
  SaveButton,
} from "../advertisements-module/components/toolbar-items";
import { ItemPage } from "../advertisements-module/components";
import { DeleteButton } from "../advertisements-module/components/toolbar-items";

const config: IModuleConfig = {
  id: "advertisements",
  name: "Advertisements",
  icon: faRectangleAd,
  moduleType: ModuleTypeEnum.DualPaneLibrary,
  allowSearch: true,
  components: {
    libraryCard: AdCard,
    itemPage: ItemPage,
  },
  services: {
    getByPage: dataService.getByPage,
    getSingle: dataService.getSingle,
  },
  utilities: {
    extractKey: (d: any) => d._id,
    extractItemPath: (d: any) => d._id,
    extractItemBreadcrumb: (d: any) => `${d.data.title}`,
  },
  toolbarItems: {
    library: [AddButton],
    create: [],
    edit: [SaveButton, CancelButton],
    view: [BackButton, EditButton, DeleteButton],
  },
};

export default config;
