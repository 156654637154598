import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { hideModal, showModal } from "../../../../components/modal/Modal";
import { useStateReducer } from "../../../../hooks";
import { dataService } from "../../services";
import "./_styles.scss";

interface CancellationItem {
  firstName: string;
  lastName: string;
  _id: number;
  pendingCancellationEventIds: Array<string>;
}

export default function ViewItem(props: CancellationItem) {
  const [state, setState] = useStateReducer({
    name: `${props.firstName} ${props.lastName}` || null,
    pendingCancellationEventIds: props.pendingCancellationEventIds || null,
    loading: false,
    loadingIndex: null,
    cancelLoader: false,
    revertLoader: false,
  });

  useEffect(() => {
    setState({
      name: `${props.firstName} ${props.lastName}` || null,
      pendingCancellationEventIds: props.pendingCancellationEventIds || null,
    });
  }, [props]);

  const finalizeCancellation = async (idx: number) => {
    const response = await dataService.finalizeCancellation(
      props._id,
      state.pendingCancellationEventIds[idx]
    );

    if (response.success != true) {
      hideModal();
      return;
    }

    const newState = {
      pendingCancellations: state.pendingCancellationEventIds.splice(idx, 1),
    };
    setState({ ...state, newState });
    hideModal();
  };

  const revertCancellation = async (idx: number) => {
    const response = await dataService.revertCancellation(
      props._id,
      state.pendingCancellationEventIds[idx]
    );

    if (response.success != true) {
      hideModal();
      return;
    }

    const newState = {
      pendingCancellations: state.pendingCancellationEventIds.splice(idx, 1),
    };
    setState({ ...state, newState });
    hideModal();
  };

  const handleClick = async (idx: number) => {
    setState({ loading: true, loadingIndex: idx, cancelLoader: true });
    const response = await dataService.getEventDetails(
      state.pendingCancellationEventIds[idx]
    );
    setState({ loading: false, loadingIndex: null });

    if (response.success == false) {
      showModal({
        content: `Response code: ${response.status}`,
        options: {
          title: `Event fetch failed`,
          actions: [
            {
              text: "Ok",
              primary: true,
              onClick: () => hideModal(),
            },
          ],
        },
      });
      return;
    }

    showModal({
      content: <ModalContent {...response} />,
      options: {
        title: `Finalize this pending cancellation?`,
        actions: [
          {
            text: "Yes",
            primary: true,
            onClick: () => finalizeCancellation(idx),
          },
          {
            text: "Cancel",
            onClick: () => {
              setState({ loading: false, cancelLoader: false });
              hideModal();
            },
          },
        ],
      },
    });
  };

  const handleRevertClick = async (idx: number) => {
    setState({ loading: true, loadingIndex: idx, revertLoader: true });
    const response = await dataService.getEventDetails(
      state.pendingCancellationEventIds[idx]
    );
    setState({ loading: false, loadingIndex: null });

    if (response.success == false) {
      showModal({
        content: `Response code: ${response.status}`,
        options: {
          title: `Event fetch failed`,
          actions: [
            {
              text: "Ok",
              primary: true,
              onClick: () => hideModal(),
            },
          ],
        },
      });
      return;
    }

    showModal({
      content: <ModalContent {...response} />,
      options: {
        title: `Revert this pending cancellation?`,
        actions: [
          {
            text: "Yes",
            primary: true,
            onClick: () => revertCancellation(idx),
          },
          {
            text: "Cancel",
            onClick: () => {
              setState({ loading: false, revertLoader: false });
              hideModal();
            },
          },
        ],
      },
    });
  };

  console.log({ props });

  return (
    <div className="order-item" key={props.firstName}>
      <div className="category-row">
        <div className="selected-user">{state.name}</div>
      </div>

      <div className="pending-cancellation-grid">
        {state.pendingCancellationEventIds?.map((pce: string, idx: number) => {
          return (
            <div className="cancellation-row" key={`cancellation-${idx}`}>
              <div>{pce}</div>
              <div>
                <Button
                  onClick={() => handleClick(idx)}
                  className="cancel-button"
                  color="info"
                >
                  {state.loading === true &&
                  state.loadingIndex === idx &&
                  state.cancelLoader === true ? (
                    <FontAwesomeIcon icon={faSpinner} />
                  ) : (
                    "Cancel"
                  )}
                </Button>
                <Button
                  onClick={() => handleRevertClick(idx)}
                  className="revert-button"
                  color="info"
                >
                  {state.loading === true &&
                  state.loadingIndex === idx &&
                  state.revertLoader === true ? (
                    <FontAwesomeIcon icon={faSpinner} />
                  ) : (
                    "Revert"
                  )}
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function ModalContent(props: any) {
  return (
    <div className="cancellation-modal">
      <div className="title">{props.data.eventName}</div>
      <div className="id-row">
        <div className="event-id-placeholder">EVENT ID - </div>
        <div className="event-id">{props.data.eventId}</div>
      </div>
      <div className="description">{props.data.description}</div>
    </div>
  );
}
