import notificationsModule from "./notification-module";
import publicationsModule from "./publications-module";
import cancellationsModule from "./cancellations-module";
import advertisementsModule from "./advertisements-module";

// Export the modules in the order they should appear in the navigation menu
const modules = [
  notificationsModule,
  publicationsModule,
  cancellationsModule,
  advertisementsModule,
];

export default modules;
