import {
  IDeleteSingleArgs,
  IGetSingleArgs,
  IPageAndSearchArgs,
  ISearchArgs,
  IUpdateSingleArgs,
} from "../../../interfaces";
import { asyncify } from "../../../utilities";
import { goFetch } from "../../../utilities/goFetch";
import dummyLibraryData from "./_dummyData";

class Service {
  async getByPage({ search, pageNo }: IPageAndSearchArgs) {
    const res: any = await goFetch(
      `${process.env.REACT_APP_URL}advertisements/page?search=${search}&pageNo=${pageNo}&pageSize=100`
    )
      .withHeaders({ "Content-Type": "application/json" })
      .get();
    const jsonResponse = await res.json();
    return jsonResponse;
  }

  async getSingle({ key }: IGetSingleArgs) {
    const res: any = await goFetch(
      `${process.env.REACT_APP_URL}advertisements/${key}`
    )
      .withHeaders({ "Content-Type": "application/json" })
      .get();
    const jsonResponse = await res.json();
    return jsonResponse;
  }

  async search({ search }: ISearchArgs) {
    return await asyncify(
      () => dummyLibraryData.filter((d) => new RegExp(search).test(d.name)),
      200
    );
  }

  async searchMembers({ search, pageNo }: IPageAndSearchArgs) {
    const res: any = await goFetch(
      `${
        process.env.REACT_APP_URL
      }users/members?pageNo=${pageNo}&pageSize=100&search=${encodeURI(
        search ?? ""
      )}`
    )
      .withHeaders({ "Content-Type": "application/json" })

      .get();
    const jsonResponse = await res.json();
    if (!jsonResponse?.data) return null;
    else return jsonResponse;
  }

  async getMemberships() {
    const res: any = await goFetch(
      `${process.env.REACT_APP_URL}users/memberships`
    )
      .withHeaders({ "Content-Type": "application/json" })
      .get();
    const jsonResponse = await res.json();
    if (!jsonResponse?.data) return null;
    else return jsonResponse.data;
  }

  async createType(data: any) {
    const res: any = await goFetch(
      `${process.env.REACT_APP_URL}advertisements/types/add`
    )
      .withHeaders({ "Content-Type": "application/json" })
      .withBody({
        name: data.name,
        slug: data.slug,
      })
      .post();
    const jsonResponse = await res.json();
    return jsonResponse;
  }

  async updateType(data: any) {
    const res: any = await goFetch(
      `${process.env.REACT_APP_URL}advertisements/types/update`
    )
      .withHeaders({ "Content-Type": "application/json" })
      .withBody({
        id: data.id,
        name: data.name,
        slug: data.slug,
      })
      .patch();
    const jsonResponse = await res.json();
    return jsonResponse;
  }

  async createCategory(data: any) {
    const res: any = await goFetch(
      `${process.env.REACT_APP_URL}advertisements/categories/add`
    )
      .withHeaders({ "Content-Type": "application/json" })
      .withBody({
        name: data.name,
        slug: data.slug,
        parentSlug: data.parentSlug,
      })
      .post();
    const jsonResponse = await res.json();
    return jsonResponse;
  }

  async updateCategory(data: any) {
    const res: any = await goFetch(
      `${process.env.REACT_APP_URL}advertisements/categories/update`
    )
      .withHeaders({ "Content-Type": "application/json" })
      .withBody({
        id: data.id,
        name: data.name,
        slug: data.slug,
        parentSlug: data.parentSlug,
      })
      .patch();
    const jsonResponse = await res.json();
    return jsonResponse;
  }

  async getAllCategories() {
    const res: any = await goFetch(
      `${process.env.REACT_APP_URL}advertisements/categories`
    )
      .withHeaders({ "Content-Type": "application/json" })
      .get();

    const jsonResponse = await res.json();
    if (!jsonResponse?.data) return null;
    else return jsonResponse.data;
  }

  async getAllTypes() {
    const res: any = await goFetch(
      `${process.env.REACT_APP_URL}advertisements/types`
    )
      .withHeaders({ "Content-Type": "application/json" })
      .get();

    const jsonResponse = await res.json();
    if (!jsonResponse?.data) return null;
    else return jsonResponse.data;
  }

  async getAllAdvertisements() {
    const res: any = await goFetch(
      `${process.env.REACT_APP_URL}advertisements/`
    )
      .withHeaders({ "Content-Type": "application/json" })
      .get();

    const jsonResponse = await res.json();
    if (!jsonResponse?.data) return null;
    else return jsonResponse.data;
  }

  async createAdvertisement(data: any) {
    const res: any = await goFetch(
      `${process.env.REACT_APP_URL}advertisements/add`
    )
      .withHeaders({ "Content-Type": "application/json" })
      .withBody({
        title: data.title,
        url: data.url,
        link: data.link,
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
        displayFrequency: data.displayFrequency,
      })
      .post();
    const jsonResponse = await res.json();
    return jsonResponse;
  }

  async updateAdvertisement(id: string, data: any) {
    const res: any = await goFetch(
      `${process.env.REACT_APP_URL}advertisements/update/${id}`
    )
      .withHeaders({ "Content-Type": "application/json" })
      .withBody({
        title: data.title,
        url: data.url,
        link: data.link,
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
        displayFrequency: data.displayFrequency,
      })
      .patch();
    const jsonResponse = await res.json();
    return jsonResponse;
  }

  async deleteAdvertisement(id: string) {
    const res: any = await goFetch(
      `${process.env.REACT_APP_URL}advertisements/delete/${id}`
    )
      .withHeaders({ "Content-Type": "application/json" })

      .post();
    const jsonResponse = await res.json();
    return jsonResponse;
  }

  async update({ key, ...rest }: IUpdateSingleArgs) {
    return await asyncify(() => {
      let toUpdate: any = dummyLibraryData.filter((d) => d.id === key)[0];

      Object.keys(rest).map((k: string) => {
        toUpdate[k] = rest[k];
        return k;
      });

      return toUpdate;
    }, 200);
  }

  async delete({ key }: IDeleteSingleArgs) {
    return await asyncify(async () => {
      const toDelete = await this.getSingle({ key });
      const index = dummyLibraryData.indexOf(toDelete);
      dummyLibraryData.splice(index, 1);
    }, 200);
  }
}

export default new Service();
