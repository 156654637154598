import "./_styles.scss";
import React, { useEffect, useRef } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { IModuleConfig } from "../../../../interfaces";
import { publish, useStateReducer } from "../../../../hooks";
import { hideModal, showModal } from "../../../../components/modal/Modal";
import { EditModal } from "./EditModal";
import { dataService } from "../../services";
import { StateTopicEnum } from "../../../../enums";

interface EditItemProps {
  data?: any;
  config: IModuleConfig;
}

export default function EditItem({ data, config }: EditItemProps) {
  const categoryName = useRef();
  const categorySlug = useRef();
  const typeName = useRef();
  const typeSlug = useRef();
  const [state, setState] = useStateReducer({
    categoryName: "",
    categorySlug: "",
    types: [],
    categories: [],
    filteredCategories: [],
    type: data?.type || "",
    category: data?.category || "",
    description: data?.description || "",
    documentLink: data?.documentLink || "",
    pageNumber: 1,
    pageCount: 100,
  });

  const getTypes = async () => {
    const typeResponse = await dataService.getAllTypes();

    setState({ types: typeResponse });
  };
  const getCategories = async () => {
    const catResponse = await dataService.getAllCategories();
    const _filteredCategories = catResponse.filter(
      (cat: any) => cat.parentSlug == state.type
    );

    setState({
      categories: catResponse,
      filteredCategories: _filteredCategories,
    });
  };

  useEffect(() => {
    getCategories();
    getTypes();
    setTimeout(() => {
      publish(StateTopicEnum.Publication, { ...state });
    }, 300);
  }, []);

  const handleChange = (target: any, value: any) => {
    if (target == "type") {
      const _filteredCategories = state.categories.filter(
        (cat: any) => cat.parentSlug == value
      );
      const newState = {
        ...state,
        [target]: value,
        filteredCategories: _filteredCategories,
      };
      setState({ ...newState });
      publish(StateTopicEnum.Publication, { ...newState });
    } else {
      const newState = {
        ...state,
        [target]: value,
      };
      setState({ ...newState });
      publish(StateTopicEnum.Publication, { ...newState });
    }
  };

  const saveItem = async (itemType: any, action: string, current?: any) => {
    let response = null;

    if (action == "create") {
      if (itemType == "Category")
        response = await dataService.createCategory({
          name: categoryName.current,
          slug: categorySlug.current,
          parentSlug: typeSlug.current || state.type,
        });

      if (itemType == "Type")
        response = await dataService.createType({
          name: typeName.current,
          slug: typeSlug.current,
        });
    }

    if (action == "edit") {
      if (itemType == "Category")
        response = await dataService.updateCategory({
          id: current._id,
          name: categoryName.current,
          slug: categorySlug.current,
          parentSlug: typeSlug.current || state.type,
        });

      if (itemType == "Type")
        response = await dataService.updateType({
          id: current._id,
          name: typeName.current,
          slug: typeSlug.current,
        });
    }

    if (response && response.success === true) {
      getCategories();
      getTypes();
      hideModal();
    }
  };

  const onUpdateNewModalFieldValue = (target: string, value: any) => {
    setState({ [target]: value });

    if (target == "categoryName") categoryName.current = value;
    if (target == "categorySlug") categorySlug.current = value;
    if (target == "typeName") typeName.current = value;
    if (target == "typeSlug") typeSlug.current = value;
  };

  const addItem = (itemType: string) => {
    showModal({
      content: (
        <EditModal
          itemType={itemType}
          onUpdateValue={onUpdateNewModalFieldValue}
        />
      ),
      options: {
        title: `Add ${itemType}`,
        actions: [
          {
            text: "Save",
            primary: true,
            onClick: () => saveItem(itemType, "create"),
          },
          {
            text: "Cancel",
            onClick: () => {
              hideModal();
            },
          },
        ],
      },
    });
  };

  const editItem = (itemType: string) => {
    let currentItem: any = null;

    if (itemType == "Type")
      currentItem = state.types.filter((typ: any) => typ.slug == state.type);

    if (itemType == "Category")
      currentItem = state.categories.filter(
        (cat: any) => cat.slug == state.category
      );

    showModal({
      content: (
        <EditModal
          itemType={itemType}
          name={currentItem[0]?.name}
          slug={currentItem[0]?.slug}
          onUpdateValue={onUpdateNewModalFieldValue}
        />
      ),
      options: {
        title: `Edit ${itemType}`,
        actions: [
          {
            text: "Save",
            primary: true,
            onClick: () => saveItem(itemType, "edit", currentItem[0]),
          },
          {
            text: "Cancel",
            onClick: () => {
              hideModal();
            },
          },
        ],
      },
    });
  };

  return (
    <div className="order-item">
      <div className="type-row">
        <FormControl fullWidth className="publications-field">
          <InputLabel id="type-label">Publication Type *</InputLabel>
          <Select
            labelId="type-label"
            id="type"
            label="Publication Type"
            value={state.type}
            onChange={(e) => handleChange("type", e.target.value)}
            required
          >
            {state.types.map((type: any, idx: number) => (
              <MenuItem key={idx} className="option" value={type.slug}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          className="edit-field"
          onClick={() => editItem("Type")}
          disabled={!state.type}
        >
          Edit Type
        </Button>
        <Button className="add-field" onClick={() => addItem("Type")}>
          Add Type
        </Button>
      </div>

      <div className="category-row">
        <FormControl fullWidth className="publications-field">
          {!state.type || state.type == "" ? (
            <TextField
              placeholder="Category - Choose Type first"
              inputProps={{ readOnly: true }}
            ></TextField>
          ) : (
            <>
              <InputLabel id="category-label">Category</InputLabel>
              <Select
                labelId="category-label"
                id="category"
                label="Category"
                onChange={(e) => handleChange("category", e.target.value)}
                disabled={!state.type}
              >
                {state.filteredCategories?.map((fCat: any, idx: any) => (
                  <MenuItem key={idx} value={fCat.slug}>
                    {fCat.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        </FormControl>

        <Button
          className="edit-field"
          onClick={() => editItem("Category")}
          disabled={!state.category}
        >
          Edit Category
        </Button>

        <Button
          className="add-field"
          onClick={() => addItem("Category")}
          disabled={!state.type || state.type == ""}
        >
          Add Category
        </Button>
      </div>

      <FormControl fullWidth className="publications-field">
        <TextField
          id="description"
          value={state.description}
          label="Description"
          placeholder="Please give this document a name"
          onChange={(e) => handleChange("description", e.target.value)}
          required
        ></TextField>
      </FormControl>

      <FormControl fullWidth>
        <TextField
          id="document-link"
          value={state.documentLink}
          label="Document Link"
          placeholder="https://storage.com/image/1.jpg"
          onChange={(e) => handleChange("documentLink", e.target.value)}
          required
        ></TextField>
      </FormControl>
    </div>
  );
}
