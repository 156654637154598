import React from "react";
import { Scrollable } from "../../../../components";
import LibraryCard from "../../../../components/library/LibraryCard";
import "./_styles.scss";

interface ViewItemProps {
  data?: any;
}

export default function ViewItem({ data }: ViewItemProps) {
  const { status, notificationTitle, notificationBody } = data.data;

  const selectedUsers: number[] = data?.data?.selectedUsers ?? [];
  const membershipTypes: number[] = data?.data?.membershipTypes ?? [];

  return (
    <div className="order-item">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingRight: "2%",
        }}
      >
        <h2 className="heading">
          {notificationTitle ? notificationTitle : "No Title"}
        </h2>
        <div
          style={{
            display: "flex",
          }}
        >
          <h1
            style={{
              paddingRight: "4px",
            }}
          >
            Status:
          </h1>
          <h1 className="heading">{status ? status : "Unkown"}</h1>
        </div>
      </div>
      <h3> Message Detail</h3>
      <p>{notificationBody ? notificationBody : "No Description"}</p>
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "inline-block",
            width: "48%",
            marginRight: "10px",
            height: "60vh",
            backgroundColor: "#f5f5f5",
          }}
        >
          <h1 className="heading content">Member Filters</h1>
          <div className="liststyle">
            <Scrollable>
              <div className="h-app-library-items">
                {!selectedUsers ? (
                  <span className="loading content">
                    No Member Filters Applied.
                  </span>
                ) : (
                  selectedUsers.map((d: any) => {
                    return (
                      <LibraryCard key={`library-item-${d}`}>
                        <p className="content">{d}</p>
                      </LibraryCard>
                    );
                  })
                )}
              </div>
            </Scrollable>
          </div>
        </div>

        <div
          style={{
            display: "inline-block",
            width: "48%",
            height: "60vh",
            backgroundColor: "#f5f5f5",
          }}
        >
          <h1 className="heading content">Membership Filters</h1>
          <div className="liststyle">
            <Scrollable className="items">
              <div className="h-app-library-items">
                {!membershipTypes ? (
                  <span className="loading content">
                    No Membership Filter Applied.
                  </span>
                ) : (
                  membershipTypes.map((d: any) => {
                    return (
                      <LibraryCard key={`library-item-${d}`}>
                        <p className="content">{d}</p>
                      </LibraryCard>
                    );
                  })
                )}
              </div>
            </Scrollable>
          </div>
        </div>
      </div>
    </div>
  );
}
