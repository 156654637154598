import { asyncify } from "../../utilities";
import { goFetch } from "../../utilities/goFetch";
import data from "./_dummyData";

interface LoginArgs {
  username: string;
  password: string;
}

interface GetProfileArgs {
  username: string;
}

class Service {
  async checkAuthentication() {
    const userRes: any = await goFetch(`${process.env.REACT_APP_URL}users`)
      .withHeaders({ "Content-Type": "application/json" })
      .get();
    const userResponse = await userRes.json();

    if (!userResponse?.success) return null;
    //users
    else return userResponse.data;
  }

  async login({ username, password }: LoginArgs) {
    const res: any = await goFetch(
      `${process.env.REACT_APP_URL}notification/login`
    )
      .withHeaders({ "Content-Type": "application/json" })
      .withBody({
        username: username,
        password: password,
        userType: "admin",
      })
      .post();
    const jsonResponse = await res.json();

    if (!jsonResponse?.success) return null;

    const userRes: any = await goFetch(`${process.env.REACT_APP_URL}users`)
      .withHeaders({ "Content-Type": "application/json" })
      .get();
    const userResponse = await userRes.json();

    if (!userResponse?.success) return null;
    else return userResponse;
  }

  async logout() {
    document.cookie = "token=;";
  }

  async getProfile({ username }: GetProfileArgs) {
    return await asyncify(() => {}, 400);
  }
}

export default new Service();
