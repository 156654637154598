import React from "react";
import "./_styles.scss";

export default function ProfilePage() {
  return (
    <div className="profile-page">
      Customizable profile page renders here!
      <p>Located in "/src/_global/components/profile-page"</p>
    </div>
  );
}
