import { AppLogo, ProfilePage, SettingsPage } from "../_global/components";
import securityService from "../_global/services/securityService";

const config = {
  // To display in the title of the browser or tab
  applicationName: "My App",
  // The theme to apply to the Material UI library
  theme: "light",
  // To render settings gear or not
  allowSettings: true,
  components: {
    // The component to render for the app logo on the side nav and login page
    appLogo: AppLogo,
    // The component to render when the profile page is accessed
    userProfile: ProfilePage,
    // The component to render when the settings page is accessed
    settings: SettingsPage,
  },
  services: {
    // The service to call when the app loads to check if a user is signed in or not
    checkAuthentication: securityService.checkAuthentication,
    // The service to call when authenticating a user
    signInUser: securityService.login,
    // The service to call when signing a user out
    signOutUser: securityService.logout,
    // The service to call when accessing a user's profile
    getUserProfile: securityService.getProfile,
  },
  utilities: {
    // A utility used to extract the user object from the authentication response
    extractUserFromAuthResponse: (res: any) => res,
    // A utility used to extract the user's account name from the user object
    extractAccountNameFromUser: (user: any) =>
      user ? user?.firstName + " " + user?.lastName : "",
    // A utility used to extract the user's display name from the user object
    extractDisplayNameFromUser: (user: any) =>
      user ? user?.firstName + " " + user?.lastName : "",
  },
};

export default config;
