import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonBase } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { classNameBuilder } from "../../utilities";

interface ToolButtonProps {
  icon?: IconProp;
  text?: string;
  path?: string;
  className?: string;
  onClick?: Function;
  primary?: boolean;
  raised?: boolean;
}

export default function ToolButton({
  icon,
  text,
  path,
  className,
  onClick,
  primary,
  raised,
}: ToolButtonProps) {
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    if (disabled) return;

    const result = onClick ? onClick() : true;

    if (result instanceof Promise) {
      setDisabled(true);
      result
        .then((r) => {
          setDisabled(false);

          if (r && path) navigate(path, { replace: false });
        })
        .catch(() => setDisabled(false));

      return;
    }

    if (result && path) navigate(path, { replace: false });
  };

  const _icon = disabled ? faSpinner : icon;

  return (
    <ButtonBase
      disabled={disabled}
      className={classNameBuilder(
        "h-app-tool-button",
        primary ? "primary" : "",
        raised ? "raised" : "",
        className ?? ""
      )}
      onClick={handleClick}
    >
      {_icon && <FontAwesomeIcon icon={_icon} spin={disabled} />}
      {text}
    </ButtonBase>
  );
}
