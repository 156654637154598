import React from "react";
import "./_styles.scss";

interface MemberCardProps {
  data?: any;
}

export default function MemberCard({ data }: MemberCardProps) {
  return <p className="member-card">{`${data.firstName} ${data.lastName}`}</p>;
}
